import React from "react";
import {graphql} from "gatsby";
import MixPostGrid from "../components/mix-post-grid";
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import SharePopupModel from "../components/share-popup-model";
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import ReadMoreBtn from "../components/read-more-btn";
import globe from "../globals"
import images from "../components/images";




export default ({data}) => {

    let tag = data.allWordpressTag.edges[0].node;

    let yoast_head = globe.domainReplace(tag.yoast_head)
    let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || ''
    let schema = temp !== undefined && temp !== null && temp.length > 70 ? temp.substring(62, temp.length-9) : {}


    return (
        <div>

            <Helmet>
                <html lang="en" />
                <title>{tag.name}  | Metrify India</title>

                {tag.yoast_head.match(/name="description"/g) === null ? <meta name="description" content={tag.name +" - Metrify India"}/> : null}
                { ReactHtmlParser(yoast_head) }
                <meta property="og:image" content={"https://metrifyindia.com" + images.ogLogo} />

                <script type="application/ld+json">
                    {schema}
                </script>

            </Helmet>
            <Header/>
            <Menu/>


            <div className="section-title pt-5">
                <h1 className="section-title-heading fsm-theme-heading">{tag.name}</h1>
            </div>

            <div className="container-fluid">
                <div className="container">

                    <MixPostGrid data={{newsArr:data.allWordpressPost.edges,regulatoryArr:data.allWordpressWpRegulatoryUpdates.edges,insightArr:data.allWordpressWpRegulatoryInsight.edges}} />

                </div>
            </div>

            <div className={"py-4 text-center"}>
                <ReadMoreBtn type={'link'} text={"View All Tags"} url={"/tags/"}/>
            </div>


            <Footer/>
            <SharePopupModel name={"shareModal"} />
        </div>
    )
}

export const query = graphql`
  query postQuery($slug: String!, $name: String!) {
  allWordpressTag(filter: { slug: { eq: $slug } }) {
        edges {
          node {
            link
            name
            slug
            wordpress_id
            yoast_head
          }
        }
      }
    allWordpressPost(sort: {fields: [date]}, filter: {tags: {elemMatch: {name: {eq: $name}}}}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            author {
              name
            }
            compliance_services
            yoast_head
          }
        }
      }
        allWordpressWpRegulatoryInsight(sort: {fields: [date]}, filter: {tags: {elemMatch: {name: {eq: $name}}}}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            compliance_services
            yoast_head
            content
          }
        }
      }
      allWordpressWpRegulatoryUpdates(sort: {fields: [date]}, filter: {tags: {elemMatch: {name: {eq: $name}}}}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            compliance_services
            yoast_head
            
            acf {
              date_of_implementation
              draft_or_gazette
              impacted_products
              reference
              reference_regulation
              summary
            }
            author {
              name
            }
          }
        }
      }
  }`
